import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  colors, Title1, Input, ErrorText, mobileThresholdPixels,
} from '../styledComponents';
import { Button, margins } from '../home/v2/StyledComponents';
import routesMap from '../../Routes';

const Content = styled.div`
  margin: 50px auto;
  margin-top: 0px;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: 10px 0px;
  }
`;

const Text = styled.p`
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin: ${margins.s};
`;

const LoginBlock = ({
  isLoading, error, login, inputChange,
}) => (
  <Content>
    <Title1>Déjà client ?</Title1>
    <div>
      <Input
        type="email"
        placeholder="Votre adresse email"
        onChange={(e) => inputChange('email', e.target.value)}
      />
    </div>
    <div>
      <Input
        type="password"
        placeholder="Votre mot de passe"
        onChange={(e) => inputChange('password', e.target.value)}
      />
    </div>
    {isLoading
      ? <p>chargement</p>
      : (
        <ButtonContainer>
          <Button type="button" onClick={() => login()}>
            Se connecter
          </Button>
        </ButtonContainer>
      )}
    {error && <ErrorText>Email/mot de passe incorrect(s)</ErrorText>}
    <Link to="/mot-de-passe-oublie">mot de passe oublié ?</Link>

    <Title1>Pas encore de compte ?</Title1>
    <Text>
      Pour commander, c‘est
      <Link to={routesMap.Step1.url}>ici</Link>
    </Text>
  </Content>
);

LoginBlock.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  login: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
};

LoginBlock.defaultProps = {
  isLoading: false,
  error: false,
};

export default LoginBlock;
